import React from "react";
import Banner from "../components/CommonBanner";
import HeaderMenuBS from "../components/HeaderMenuBS"
import PortfolioDetails from "../containers/PortfolioPage1/PortfolioDetails";
import Footer from "../components/Footer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImg from '../assets/portfolio-1-images/PortfolioDetails/banner.jpg';

const PortfolioDetailsPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS />
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Our Portfolio"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Our Portfolio"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/portfoliodetails-1"
        />
        <PortfolioDetails />
        <Footer />
    </Layout>
)

export default PortfolioDetailsPage1;