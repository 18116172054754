import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const PortfolioDetailsSection = styled.section`
    padding:100px 0px;
    border-bottom:1px solid #ccc;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const PortfolioDetailsWrapper = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;
export const PortfolioContent = styled.div`
    margin-bottom:30px;
`;
export const PortfolioImageHolder = styled.div`
    margin-bottom:20px;

    img{
        border-radius:4px;
    }
`;
export const PortfolioTextLayout = styled.div`

`;

export const PortfolioFeatureLayout = styled.div`

`;

export const PortfolioIcon = styled.img`
    border-radius:4px;
`;

export const RelatedPostLayout = styled.div`
    a{
        text-align:left;
        font-size:20px;
        line-height:25px;
        color:#333;
        font-family: 'Source Serif Pro', serif;
    }
    a h6{
        margin-bottom:0;
    }
`;
export const RelatedPostHeading = styled.h4`
    text-align:left;
    margin-bottom:0px;
`;
export const RelatedPostImgHolder = styled.div`
    margin-bottom:10px;
    margin-top:30px;

    img{
        border-radius:4px;
    }
`;
export const Divider = styled.hr`
    margin:40px 0px;
`;

export const PostImgHolder = styled.div`
    border-radius:4px;
    margin-bottom:20px;
    img{
        border-radius:4px;
    }
`;


export const RightTextContainer = styled.div`
    margin-bottom:0px;
    text-align:left;

    table{
        margin-bottom:0;
    }

    table tr td{
        font-size:16px;
        color:#424242;
        padding:6px 6px 6px 0px;
        border-bottom:none;

        @media ${device.mobileL}{
            font-size:14px;
        }
    }
`;
