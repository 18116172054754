import React from 'react';
import {PortfolioDetailsSection,PortfolioDetailsWrapper,PortfolioContent,PortfolioImageHolder,PortfolioTextLayout,
    Divider,
    RelatedPostLayout,RelatedPostHeading,RelatedPostImgHolder,
    PostImgHolder,RightTextContainer
} from './portfoliodetails.style';
import {Row,Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const PortfolioDetails= () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                portfolioPage1Json{
                    PortfolioDetails{
                        PortfolioImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        PortfolioHeading
                        PortfolioText1
                        PortfolioText2
                        PortfolioText3
                        RelatedPostHeading
                        RelatedPostDetails{
                            RelatedPostImg{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            RelatedPostTitle
                        }
                        RightImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        RightHeading
                        RightBlock{
                            LeftSpanText
                            RightSpanText
                        }
                    }
                }
            }
    `);
    const PortfolioData = JSONData.portfolioPage1Json.PortfolioDetails;

    return (
        <PortfolioDetailsSection>
            <Container>
                <Row>
                    <Col lg={8}>
                        <PortfolioDetailsWrapper>
                            <PortfolioContent>
                                <PortfolioImageHolder>
                                    <GatsImg
                                        fluid={PortfolioData.PortfolioImage.childImageSharp.fluid}
                                        alt=""
                                    />
                                </PortfolioImageHolder>
                                <PortfolioTextLayout>
                                    <h3>
                                    {PortfolioData.PortfolioHeading}
                                    </h3>
                                    <p>
                                    {PortfolioData.PortfolioText1}
                                    </p>
                                    <p>
                                    {PortfolioData.PortfolioText2}
                                    </p>
                                    <p>
                                    {PortfolioData.PortfolioText3}
                                    </p>
                                </PortfolioTextLayout>
                            </PortfolioContent>
                        </PortfolioDetailsWrapper>
                    </Col>
                    <Col lg={4}>
                        <PostImgHolder>
                            <GatsImg
                                fluid={PortfolioData.RightImage.childImageSharp.fluid}
                                alt=""
                            />
                        </PostImgHolder>
                        <h4>{PortfolioData.RightHeading}</h4>
                        <RightTextContainer>
                            <table>
                                {
                                    PortfolioData.RightBlock.map(item=>{
                                        return <tr>
                                            <td>{item.LeftSpanText}</td>
                                            <td>: {item.RightSpanText}</td>
                                        </tr>
                                    })
                                }
                            </table>
                        </RightTextContainer>
                    </Col>
                </Row>
                <Divider/>
                <RelatedPostLayout>
                    <RelatedPostHeading>
                        {PortfolioData.RelatedPostHeading}
                    </RelatedPostHeading>
                    <Row>
                        {
                            PortfolioData.RelatedPostDetails.map(item=> {
                                return <Col md={3}>
                                    <RelatedPostImgHolder>
                                        <GatsImg
                                            className="post-img"
                                            fluid={item.RelatedPostImg.childImageSharp.fluid}
                                            alt=""
                                        />
                                    </RelatedPostImgHolder>
                                    <a href="/">
                                        <h6>{item.RelatedPostTitle}</h6>
                                    </a>
                                </Col>
                            })
                        }
                    </Row>
                </RelatedPostLayout>
            </Container>
        </PortfolioDetailsSection>
    );
}
export default PortfolioDetails;
